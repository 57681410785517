import React from 'react';

const Footer = () => (
	<div className="Footer">
		<div className="Footer_container">
			<div className="Footer_top-links">
				<ul className='Footer_top-links-menu'>
					<li>
						<a
							href="https://merchforall.com/pages/sizes/"
							target="_blank"
							rel="noreferrer"
							className="Footer_top-link"
						>
							Sizes & Fit
						</a>
					</li>
					<li>
						<a
							href="https://merchforall.com/pages/shipping-and-returns/"
							target="_blank"
							rel="noreferrer"
							className="Footer_top-link"
						>
							Shipping & Returns
						</a>
					</li>
					<li>
						<a
							href="https://merchforall.com/contact/"
							target="_blank"
							rel="noreferrer"
							className="Footer_top-link"
						>
							Contact Us
						</a>
					</li>
					<li>
						<a
							href="https://merchforall.com/legal/privacy/"
							target="_blank"
							rel="noreferrer"
							className="Footer_top-link"
						>
							Privacy Policy
						</a>
					</li>
					<li>
						<a
							href="https://merchforall.com/legal/terms/"
							target="_blank"
							rel="noreferrer"
							className="Footer_top-link"
						>
							Terms of Service
						</a>
					</li>
				</ul>

				<div className='Footer_top-socials-container'>
					<ul className="Footer_top-socials-menu">
						<li>
							<a
								href="https://www.youtube.com/@notOtzdarva/featured"
								target="_blank"
								rel="noreferrer"
								className="footer-social-link"
							>
								<i className="fab fa-youtube fa-1x"></i>
							</a>
						</li>
						<li>
							<a
								href="https://www.twitch.tv/otzdarva"
								target="_blank"
								rel="noreferrer"
								className="footer-social-link"
							>
								<i className="fab fa-twitch fa-1x"></i>
							</a>
						</li>
						<li>
							<a
								href="https://twitter.com/OtzdarvaYT"
								target="_blank"
								rel="noreferrer"
								className="footer-social-link"
							>
								<i className="fab fa-twitter fa-1x"></i>
							</a>
						</li>
						<li>
							<a
								href="https://discord.com/invite/Otzdarva"
								target="_blank"
								rel="noreferrer"
								className="footer-social-link"
							>
								<i class="fab fa-discord fa-1x"></i>
							</a>
						</li>
					</ul>
				</div>


			</div>
		</div>
		<div className='Footer_bottom'>
			<span>© {new Date().getFullYear()} <a href='https://merchforall.com/' target="_blank" rel="noreferrer">Merch for All LLC</a> - All Rights Reserved</span>
		</div>
	</div>
);


export default Footer;